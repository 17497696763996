<template>
    <top-bar />
    <div class="not-found">
        <div class="not-found__container">
            
            <figure class="not-found__figure">
                <img class="not-found__illustration" src="@/assets/images/not_found.svg" alt="">
            </figure>
            <h1 class="not-found__heading">
                Page Not Found!!
            </h1>
        
            <p class="not-found__information">
                Sorry, this the page you are looking for does not exist. Please click  to go back to the
                <router-link to="/" class="not-found__link">dashboard</router-link>
            </p>
        </div>
    </div>
    <main-nav />
</template>


<script>
import TopBar from '@/components/TopBar.vue'
import MainNav from '@/components/MainNav.vue'
export default {
    data(){
        return{
            loading:false,
        }
    },
     components:{
        TopBar,
        MainNav,
    },
    methods:{
        logOut(){
            this.loading = true
            this.$store.dispatch({
            type:'logOut',
            }).then(()=>{
                this.loading = false
                this.$router.push({name:'Login'})
            }).catch((error)=>{
                this.loading = false
                this.$router.push({name:'Login'})
                console.log(error)
            })
        },
        cancel(){
            this.$router.go(-1)
        }
    }
}
</script>

<style>
    .not-found{
        display: flex;
        justify-content: center;
        padding: 0 1em;
        padding-top: 4em;
        
        overflow:scroll;
        height: 100%;
    }
    .not-found__information{
        text-align: center;
        line-height: 1.5;
        max-width:540px;
    }
    .not-found__illustration{
        width:100%;
        max-width:640px;
    }

    .not-found__heading{
        font-weight: bold;
        text-align: center;
        font-size: 2.5em;
        margin-bottom:1.5rem;
    }

    .not-found__figure{
        margin-bottom: 4em;
    }

    .not-found__link{
        font-weight: bold;
    }

</style>